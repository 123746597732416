
import Vue from "vue"
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {groupStatus, WhatsappGroup} from "@/store/types";

export default Vue.extend({
  name: "ChannelsTable",

  components: {
    ConfirmDialog,
  },

  props: {
    items: { type: Array, default: null },
  },

  data: () => ({
    showDeleteDialog: false,
    showInfoDialog: false,
    selectedGroup: null as unknown | WhatsappGroup,
    headers: [
      {
        text: "Título",
        value: "title",
        align: "start"
      },
      {
        text: "Tipo",
        value: "type",
        align: "start"
      },
      {
        text: "Condição",
        value: "status",
        align: "center"
      },
      {
        text: "Extra",
        value: "extra",
        align: "center"
      },
      {
        text: "Status",
        value: "info",
        align: "center"
      },
      {
        text: "Ações",
        value: "actions",
        width: "110",
        align: "center",
        sortable: false
      }
    ] as Array<Object>
  }),

  methods: {
    showDelDialog(item: WhatsappGroup): void {
      this.showDeleteDialog = true
      this.selectedGroup = item;
    },

    onShowInfoDialog(item: WhatsappGroup): void {
      if (item.status !== groupStatus.Pending) return;

      this.showInfoDialog = true
      this.selectedGroup = item;
    },

    onEdit(item: WhatsappGroup): void {
      this.$emit("onEdit", item)
    },

    getStatusText(status: groupStatus) {
      if (status === groupStatus.Pending) return 'Aguardando ativação'
      if (status === groupStatus.Error) return 'Contate o Suporte'
      return 'Pronto'
    },

    getStatusColor(status: groupStatus) {
      if (status === groupStatus.Pending) return 'orange'
      if (status === groupStatus.Error) return 'red'
      return 'blue'
    },

    onDelete(): void {
      this.$emit("onDelete", this.selectedGroup)
      this.showDeleteDialog = false
    }
  }
})
