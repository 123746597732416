
import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import GroupForm from "./components/GroupForm.vue"
import GroupTable from "./components/GroupTable.vue"
import { WhatsappGroup } from "@/store/types";

export default Vue.extend({
  name: "WhatsAppGroups",
  
  components: {
    FullScreenDialog,
    GroupForm,
    GroupTable
  },

  data: () => ({
    fullScreenDialog: false,
    group: null as unknown | WhatsappGroup
  }),

  computed: {
    ...mapGetters({
      groups: "whatsappGroups/getGroups",
      uid: "auth/getUid"
    })
  },

  mounted() {
    this.$store.dispatch("whatsappGroups/fetch", { uid: this.uid });
  },

  methods: {
    editCol(group: WhatsappGroup): void {
      this.group = group;
      this.openFullScreenDialog();
    },

    deleteCol(group: WhatsappGroup): void {
      this.$store.dispatch("whatsappGroups/delete", group);
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.group = null;
    },
  }
})
