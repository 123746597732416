export interface RootState {
  version: string;
}

export interface UserProfile {
  avatar?: string;
  name: string;
  email: string;
  phone: string;
  active: boolean;
  approved: boolean;
  uid: string;
}

export interface ImageVersion {
  width: number;
  height: number;
  url: string;
}

export enum mediaType {
  Image = 'IMAGE',
  Gif = 'GIF',
  Video = 'VIDEO',
}

export enum actionType {
  Link = 'LINK',
  Email = 'EMAIL',
  Pix = 'PIX',
  Unset = 'UNSET',
}

export interface UploadMetadata {
  contentType: string;
  size: number;
}

export interface ImageUpload {
  folder: string;
  filename: string;
  metadata: UploadMetadata;
  file: Blob | Uint8Array | ArrayBuffer;
}

export interface WhatsappGroupsState {
  groups: WhatsappGroup[];
}

export interface WhatsappGroup {
  id?: string;
  active: boolean;
  chat_id: string;
  link: string;
  status: groupStatus;
  type: groupTypes;
  title: string;
  code: string;
  uid: string;
  allow_ia: boolean;
  allow_short_links: boolean;
  created_at?: Date;
  updated_at?: Date;
}

export enum groupTypes {
  Shopee = 'SHOPEE',
  Magalu = 'MAGALU',
}

export enum groupStatus {
  Pending = 'PENDING',
  Error = 'ERROR',
  Ready = 'READY',
}

export interface AssetDimensions {
  width: number;
  height: number;
}

export interface Action {
  value: string;
  text: string;
  type: actionType;
}

export interface ImageVersionV2 {
  height: number;
  url: string;
  width: number;
}

export interface VideoVersionV2 {
  height: number;
  url: string;
  width: number;
}

export interface StoryV2 {
  _id: string;
  action: Action;
  user_id?: any;
  app_ids: string[];
  story_collection_id: string;
  views_count?: number;
  active: boolean;
  disable_likes: boolean;
  disable_share: boolean;
  expiring_at: number;
  expiring_days: number;
  has_video: boolean;
  is_paid_partnership: boolean;
  story_duration_secs: number;
  image_versions: ImageVersionV2[];
  video_versions: VideoVersionV2[];
  media_type: mediaType;
  created_at: Date;
  updated_at: Date;
}

export interface MobileApp {
  _id: string;
  app_name: string;
  is_active: boolean;
  created_at: Date;
  updated_at: Date;
}




