
import Vue from "vue"
import { groupStatus, groupTypes, WhatsappGroup } from "@/store/types";
import { v4 as uuidv4 } from "uuid"

export default Vue.extend({
  name: "GroupForm",

  props: {
    group: { type: Object, default: null }
  },

  data() {
    return {
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      types: [groupTypes.Magalu, groupTypes.Shopee],
      payload: {
        id: "",
        active: true,
        chat_id: "",
        link: "",
        status: groupStatus.Pending,
        type: groupTypes.Magalu,
        title: "",
        uid: this.$store.getters["auth/getUid"],
        code: uuidv4(),
        allow_ia: true,
        allow_short_links: true,
        created_at: new Date(),
        updated_at: new Date(),
      } as WhatsappGroup
    };
  },

  mounted() {
    if (this.group) {
      Object.assign(this.payload, this.group)
    }
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;

      if (this.group) {
        this.payload.updated_at = new Date()
        this.$store.dispatch("whatsappGroups/update", this.payload);
      } else {
        this.$store.dispatch("whatsappGroups/create", this.payload);
      }

      this.$emit("close");
    },

    validateForm(): Boolean {
      if (!this.payload.title) {
        this.showError("Insira o Nome do Grupo!");
        return false;
      }
      if (!this.payload.link) {
        this.showError("Insira o Seu Link!");
        return false;
      }
      return true;
    },

    isFloatNumber(value: any): Boolean {
      return isNaN(parseFloat(value));
    },

    isValidUrl(string: string): boolean {
      try {
        new URL(string);
      } catch (_) {
        return false;
      }

      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    }
  }
})
